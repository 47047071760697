import { render, staticRenderFns } from "./header.vue?vue&type=template&id=4d15233b&scoped=true"
import script from "./header.vue?vue&type=script&lang=js"
export * from "./header.vue?vue&type=script&lang=js"
import style0 from "./header.vue?vue&type=style&index=0&id=4d15233b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d15233b",
  null
  
)

component.options.__file = "header.vue"
export default component.exports