<template>
  <div>
    <div class="headers">
      <img class="img" src="../../../../assets/images/Monitor/u25.png" />
      <div class="text">
        <div
          class="tab"
          :class="activeIndex === 0 ? 'active' : ''"
          @click="changeTab(0)"
        >
          天气预报
        </div>
        <div
          class="tab"
          :class="activeIndex === 1 ? 'active' : ''"
          @click="changeTab(1)"
        >
          清晰建议
        </div>
      </div>
    </div>
    <!-- :class="index == 0 ? 'currentStyle' : ''" -->
    <div class="weatherflex" v-if="activeIndex === 0">
      <div v-for="(item, index) in weather" :key="item.date" class="item">
        <div>{{ item.reporttime }}</div>
        <div>
          <img
            class="img2"
            v-if="item.weather === '阴'"
            src="@/assets/weather/weather_icon5.png"
            alt=""
          />
          <img
            class="img2"
            v-if="item.weather === '晴'"
            src="@/assets/weather/weather_icon2.png"
            alt=""
          />
          <img
            class="img2"
            v-if="item.weather === '多云'"
            src="@/assets/weather/weather_icon3.png"
            alt=""
          />
          <img
            class="img2"
            v-if="item.weather === '雷'"
            src="@/assets/weather/weather_icon6.png"
            alt=""
          />
          <img
            class="img2"
            v-if="item.weather === '雨'"
            src="@/assets/weather/weather_icon1.png"
            alt=""
          />
          <img
            class="img2"
            v-if="item.weather === '雪'"
            src="@/assets/weather/weather_icon4.png"
            alt=""
          />
        </div>
        <div>{{ item.temperature }}℃</div>
        <div>{{ item.weather }}</div>
        <div>{{ item.winddirection }}</div>
      </div>
    </div>
    <div class="qixi" style="color: #fff" v-if="activeIndex === 1">
      <div>
        昨日天气为{{
          weather[0].nightweather
        }}天，且发电功率平均值与过去15天的功率平均值的偏差大于设置阈值，则推荐进行组件清洗
      </div>
      <div>当前阈值：20%</div>
      <div>
        <img
          class="img3"
          src="../../../../assets/images/Monitor/u240.png"
          alt=""
        />
      </div>
      <div>不建议清理</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    areaCode: {
      type: String,
      default: "",
    },
    powerStationCode: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      key: "0f3869aa9437198ab6b8d5a1f64081b0",
      weather: [],
      activeIndex: 0,
      weatherDay: {}, // 今日天气
      historyWeather: [], // 历史天气
      weatherExten: [],
    };
  },
  async created() {
    await this.getWeatherDay();
    await this.getWeatherHistory();
    await this.getWeatherExtensions();
  },
  methods: {
    // 获取天气
    // getWeather() {
    //   fetch(
    //     `https://restapi.amap.com/v3/weather/weatherInfo?city=${this.city}&key=${this.key}&extensions=all` // 如何返回三天的
    //   )
    //     .then((res) => res.json())
    //     .then((res) => {
    //       if (res.infocode === "10000") {
    //         // console.log(res,33);
    //         this.weather = res.forecasts[0].casts;
    //       }
    //     });
    // },
    // 获取定位
    // getLocation() {
    //   fetch(
    //     `https://restapi.amap.com/v3/ip?key=${this.key}`
    //   )
    //     .then((res) => res.json())
    //     .then((res) => {
    //       console.log(res, 63);
    //       if (res.infocode === "10000") {
    //         this.city = res.adcode;
    //         this.getWeather();
    //       }
    //     });

    // },

    // 获取当日天气
    async getWeatherDay() {
      const res = await this.$http.post("/powerStation/stationWeather", {
        areaCode: this.areaCode,
      });
      if (res.data.code === 200) {
        this.weatherDay = res.data.data;
        this.weatherDay.reporttime = this.weatherDay.reporttime.split(" ")[0];
        // weather 晴  winddirection 南 temperature 天气 reporttime日期
        this.weather.push(this.weatherDay);
      } else {
        this.$message.error(res.data.message);
      }
    },
    //
    // 获取历史天气
    async getWeatherHistory() {
      // 获取当前日期
      const today = new Date();
      // 获取前两天的日期
      const twoDaysAgo = new Date(today);
      const oneDaysAgo = new Date(today);
      twoDaysAgo.setDate(today.getDate() - 2);
      oneDaysAgo.setDate(today.getDate() - 1);
      // 格式化前两天的日期为 YYYY-MM-DD 格式
      const twoDate = twoDaysAgo.toISOString().split("T")[0];
      const oneDate = oneDaysAgo.toISOString().split("T")[0];

      const res = await this.$http.post("/powerStation/queryWeathers", {
        areaCode: this.areaCode,
        powerStationCode: this.powerStationCode,
        weatherBeDate: twoDate,
        weatherEddDate: oneDate,
      });
      if (res.data.code === 200) {
        res.data.data.forEach((item) => {
          item.reporttime = item.weatherDate;
        });
        this.historyWeather = res.data.data;
        this.weather = [...this.historyWeather, ...this.weather];
      } else {
        this.$message.error(res.data.message);
      }
    },

    // 获取后两天天气  /powerStation/stationExtensionsWeather
    async getWeatherExtensions() {
      const res = await this.$http.post(
        "/powerStation/stationExtensionsWeather",
        {
          areaCode: this.areaCode,
          powerStationCode: this.powerStationCode,
        }
      );
      if (res.data.code === 200) {
        res.data.data.shift();
        res.data.data.pop();
        res.data.data.forEach((item) => {
          // weather 晴  winddirection 南 temperature 天气 reporttime日期
          item.reporttime = item.date;
          item.weather = item.dayweather;
          item.winddirection = item.daywind;
          item.temperature = item.daytemp;
        });
        this.weatherExten = res.data.data;
        console.log(this.weatherExten, 214);
        this.weather = [...this.weather, ...this.weatherExten];
      } else {
        this.$message.error(res.data.message);
      }
    },

    changeTab(val) {
      this.activeIndex = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.weatherflex {
  display: flex;
  .item {
    flex: 1;
    text-align: center;
    color: #fff;
    height: 130px;
    box-sizing: border-box;
    padding-top: 30px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    font-size: 14px;
  }
  & .item:nth-child(3) {
    font-size: 16px;
    padding-top: 16px;
    height: 176px;
  }
}

.headers {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #5d7e9c;
  .text {
    margin-left: 10px;
    display: flex;
    color: #fff;
    font-weight: 700;
    width: 200px;
    justify-content: space-between;
    .tab {
      width: 100px;
      text-align: center;
      background-color: #2d5981;
      border: 1px solid #96a6b8;
      height: 25px;
      line-height: 25px;
      border-radius: 5px;
    }
  }
}
.img {
  width: 20px;
  height: 20px;
}
.img2 {
  width: 50px;
}
.img3 {
  width: 50px;
}
.blob {
  font-weight: 700;
}
.qixi {
  padding: 10px;
  box-sizing: border-box;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  // height: 213px;
  &>div{
    padding: 3px 0;
  }
}

.active {
  background-color: #02a7f0 !important;
  font-weight: 700 !important;
}
</style>
