import * as echarts from "echarts";
export const DetailLeft1 = {
  backgroundColor: "#2d5981",
  tooltip: {
    trigger: "axis",
    formatter: function (data) {
      let html = ``;
      html = `
        <div>${data[0].marker} ${data[0].seriesName}: ${data[0].value}MV</div>
        <div>${data[1].marker} ${data[1].seriesName}: ${data[1].value}W/m²</div>
        `;
      return html;
    },
  },
  legend: {
    show: true,
    x: "center",
    y: "10",
    textStyle: {
      color: "#97b3cb",
    },
    lineStyle: {
      color: "#02a7f0",
    },
    data: ["发电功率", "辐照强度"],
  },
  grid: {
    left: "10%",
  },
  xAxis: {
    type: "category",
    boundaryGap: false,
    data: [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "23",
    ],
    axisLine: {
      lineStyle: {
        color: "#fff",
        width: 1,
      },
    },
    axisLabel: {
      show: true,
      textStyle: {
        color: "#97b3cb", // x轴颜色
      },
    },
  },
  yAxis: [
    {
      type: "value",
      name: "MV",
      min: 0,
      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
      axisLabel: {
        formatter: "{value}",
        // y轴数据颜色
        textStyle: {
          color: "#fff",
        },
      },
      splitLine: {
        show: false,
      },
    },
    {
      type: "value",
      name: "W/m²",
      min: 0,
      max: 1,
      axisLabel: {
        formatter: "{value}",
        textStyle: {
          color: "#97b3cb", //y轴的字体颜色
        },
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#97b3cb", // 发电总量颜色
        },
      },
      axisTick: {
        show: false,
      },
      splitLine: {
        show: false,
      },
    },
  ],
  series: [
    {
      name: "发电功率",
      data: [0, 0, 0, 0, 1, 5,2,3,4,5,5,6,1,7, 5, 2, 0, 3, 1, 5, 0, 2, 0, 3, 1, 5],
      symbol: "circle",
      color: "#02a7f0",
      width: "5",
      type: "line",
      lineStyle: {
        color: "#02a7f0",
      },
      smooth: true,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgba(39, 106, 148,1)" }, // 起始颜色及位置
          { offset: 1, color: "rgba(55, 89, 118, 1)" }, // 终止颜色及位置
        ]),
      },
      axisLabel: {
        textStyle: {
          color: "#97b3cb",
        },
      },
    },
    {
      name: "辐照强度",
      data: [0, 2, 0, 3, 1, 5 ,2,3,4,5,5,6,1,7, 9, 2, 0, 1, 1, 5, 4, 8, 0, 4, 3, 1],
      symbol: "circle",
      color: "#ec808d",
      width: "5",
      type: "line",
      lineStyle: {
        color: "#ec808d",
      },
      smooth: true,
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgba(39, 106, 148,1)" }, // 起始颜色及位置
          { offset: 1, color: "rgba(55, 89, 118, 1)" }, // 终止颜色及位置
        ]),
      },
      axisLabel: {
        textStyle: {
          color: "#97b3cb",
        },
      },
    },
  ],
};

export const DetailLeft2 = {
  grid: {
    top: 40,
    bottom: 40,
    left: 40,
    right: 40,
  },
  tooltip: {
    trigger: "axis",
    formatter: function (data) {
      let html = ``;
      html = `
        <div>${data[0].name}</div>
        <div>${data[0].marker} ${data[0].seriesName}: ${data[0].value}kwh</div>
        <div>${data[1].marker} ${data[1].seriesName}: ${data[1].value}元</div>
        `;
      return html;
    },
  },
  legend: {
    data: ["发电功率", "辐照强度"],
    //设置颜色
    textStyle: {
      color: "#fff",
    },
  },
  xAxis: [
    {
      type: "category",
      axisLabel: {
        show: true,
        textStyle: {
          color: "#fff", // x轴颜色
        },
        formatter: "{value}",
      },
      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
      data: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
    },
  ],
  yAxis: [
    {
      type: "value",
      name: "万kwh",
      axisLabel: {
        show: true,
        textStyle: {
          color: "#fff", // x轴颜色
        },
        formatter: "{value}",
      },

      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
    },
    {
      type: "value",
      name: "元",
      axisLabel: {
        show: true,
        textStyle: {
          color: "#fff", // x轴颜色
        },
        formatter: "{value}",
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: "#fff",
          width: 1,
        },
      },
    },
  ],
  series: [
    {
      name: "发电功率",
      type: "bar",
      barWidth: "20%",
      symbol: "circle",
      itemStyle: {
        color: "#3399ff",
        barBorderRadius: [10, 10, 0, 0],
      },

      data: [
        2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6, 162.2, 32.6, 20.0, 6.4, 3.3,
      ],
    },
    {
      name: "辐照强度",
      type: "line",
      yAxisIndex: 1,
      symbol: "circle",
      itemStyle: {
        color: "#ec808d",
      },

      //线条圆润
      smooth: true,
      showSymbol: false,
      data: [2.0, 2.2, 3.3, 4.5, 6.3, 10.2, 20.3, 23.4, 23.0, 16.5, 12.0, 6.2],
    },
  ],
};

export const year = [
  "1月",
  "2月",
  "3月",
  "4月",
  "5月",
  "6月",
  "7月",
  "8月",
  "9月",
  "10月",
  "11月",
  "12月",
];

export const day = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "23",
];

export const month = [
  "1日",
  "2日",
  "3日",
  "4日",
  "5日",
  "6日",
  "7日",
  "8日",
  "9日",
  "10日",
  "11日",
  "12日",
  "13日",
  "14日",
  "15日",
  "16日",
  "17日",
  "18日",
  "19日",
  "20日",
  "21日",
  "22日",
  "23日",
  "24日",
  "25日",
  "26日",
  "27日",
  "28日",
  "29日",
  "30日",
  "31日",
];
