<template>
  <div>
    <div class="headers">
      <img class="img" src="../../../../assets/images/Monitor/u25.png" />
      <div class="text">社会贡献</div>
    </div>
    <div class="content">
      <div class="item">
        <img
          class="img2"
          src="../../../../assets/images/Monitor/u242.png"
          alt=""
        />
        <!-- <div class="blob">
          <countTo
            :startVal="0"
            :endVal="obj.yearCoalSaving * 1"
            :duration="3000"
            :decimals="2"
          ></countTo
          ><span style="font-size: 14px">t</span>
        </div> -->
        <div class="text2">节约标准煤</div>
      </div>
      <div class="item">
        <img
          class="img2"
          src="../../../../assets/images/Monitor/u245.png"
          alt=""
        />
        <div class="blob">
          <!-- <countTo
            :startVal="0"
            :endVal="obj.yearCO2Reduction * 1"
            :duration="3000"
            :decimals="2"
          ></countTo
          > -->
          <span style="font-size: 14px">t</span>
        </div>
        <div class="text2">CO2减排量</div>
      </div>
      <div class="item">
        <img
          class="img2"
          src="../../../../assets/images/Monitor/u248.png"
          alt=""
        />
        <div class="blob">
          <!-- <countTo
            :startVal="0"
            :endVal="obj.yearPowerNum * 1"
            :duration="3000"
            :decimals="2"
          ></countTo
          ><span style="font-size: 14px">棵</span> -->
        </div>
        <div class="text2">等效植树量</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      obj: {},
      powerStationCode: "2024051019503939341465472484243",
    };
  },
  created() {
    this.getPCczEmission();
  },
  methods: {
    //
    async getPCczEmission() {
      const {
        data: { data },
      } = await this.$http.post("/RealMonitoring/PCczEmission", {
        powerStationCode: this.powerStationCode,
      });
      this.obj = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.headers {
  display: flex;
  align-items: center;
  padding:10px;
  border-bottom: 1px solid #5d7e9c;
  .text {
    margin-left:10px;
    color: #fff;
    font-weight: 700;
  }
}
.img {
  width: 20px;
  height: 20px;
}
.img2 {
  width:100px;
  height:100px;
}
.blob {
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}
.content {
  display: flex;
  .item {
    flex: 1;
    text-align: center;
    padding-top: 20px;
    box-sizing: border-box;
  }
}
.text2 {
  color: #fff;
  margin-top: 15px;
}
</style>
