import { render, staticRenderFns } from "./right2.vue?vue&type=template&id=67cb1fb7&scoped=true"
import script from "./right2.vue?vue&type=script&lang=js"
export * from "./right2.vue?vue&type=script&lang=js"
import style0 from "./right2.vue?vue&type=style&index=0&id=67cb1fb7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67cb1fb7",
  null
  
)

component.options.__file = "right2.vue"
export default component.exports