<template>
  <div class="container">
    <Header :powerData="powerData" />
    <Center :powerStation="powerStation"  />
    <div class="detail-flex">
      <div style="width: 68%">
        <div class="detail-gl">
          <left1 />
        </div>
        <div class="detail-gl" style="min-height: 300px; margin-top: 20px">
          <left2 />
        </div>
      </div>
      <div style="width: 31%">
        <div class="detail-ri">
          <right1 />
        </div>
        <div class="detail-ri" style="min-height: 180px">
          <right2 :areaCode="areaCode" :powerStationCode="powerStationCode" />
        </div>
        <div class="detail-ri" style="min-height: 180px">
          <right3 />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Center from "../components/detail/center.vue";
import Header from "../components/detail/header.vue";
import left1 from "../components/detail/left1.vue";
import left2 from "../components/detail/left2.vue";
import right1 from "../components/detail/right1.vue";
import right2 from "../components/detail/right2.vue";
import right3 from "../components/detail/right3.vue";

export default {
  components: { Header, Center, left1, left2, right1, right2, right3 },
  data() {
    return {
      powerStationCode: localStorage.getItem("powerStationCode"),
      powerData: {}, // 电站数据
      areaCode: "",
      powerStation:{}, //厂站数据
    };
  },
  created() {
    this.getQueryByPowerStation();
    this.getPowerStation()
    this.areaCode = this.$route.query.areaCode;
  },
  methods: {
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#D9001B";
      }
      if (percentage < 70) {
        return "#e6a23c";
      }
      return "#67c23a";
    },
    //
    // 获取电站数据
    async getQueryByPowerStation() {
      const res = await this.$http.post(
        "/powerStationDevice/queryByPowerStation",
        { powerStationCode: this.powerStationCode }
      );
      if (res.data.code === 200) {
        this.powerData = res.data.data;
      } else {
        this.$message.error(res.data.message);
      }
    },

    //获取厂站详情
    async getPowerStation() {
      const res = await this.$http.post("/powerStation/queryOne", {
        powerStationCode: this.powerStationCode,
      });
      if (res.data.code === 200) {
        this.powerStation = res.data.data;
        console.log(  this.powerStation , 83);
      } else {
        this.$message.error(res.data.message);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  background-color: #223f6c;
  padding: 20px;
  box-sizing: border-box;
  min-height: 90vh;

  .detail-flex {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .detail-gl {
      width: 100%;
      height: 350px;
      background-color: #2d5981;
      margin-top: 15px;
      border-radius: 8px;
    }

    .detail-ri {
      width: 100%;
      height: 230px;
      background-color: #2d5981;
      margin-top: 15px;
      border-radius: 8px;
    }
  }
}

li {
  list-style: none;
}
</style>
