<template>
  <div class="box-center">
    <div class="left">
      <div class="headers">
        <img class="img" src="../../../../assets/images/Monitor/u25.png" />
        <div class="text">能量流</div>
      </div>
      <div class="left-bottom">
        <img
          class="img1"
          src="../../../../assets/images/Monitor/u262.png"
          alt=""
        />
        <div class="left-text">
          <div>组串</div>
          <div>逆变器</div>
          <div>电网</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="headers">
        <img class="img" src="../../../../assets/images/Monitor/u25.png" />
        <div class="text">场站概况</div>
      </div>
      <div class="right-bottom">
        <img
          class="right-bottom-img"
          src="../../../../assets/images/Monitor/u30.png"
          alt=""
        />
        <div class="right-bottom-content">
          <div style="font-size: 16px;color:#3a8ad9">{{ powerStation.powerStationName }}</div>
          <div>厂站地址：{{ powerStation.address }}</div>
          <div style="display: flex">
            场站状态：
            <div v-if="powerStation.powerStationStatus == 1">
              <span class="success"></span>正常
            </div>
            <div v-if="powerStation.powerStationStatus == 2">
              <span class="error"></span>停机
            </div>
          </div>
          <div>联系人：{{ powerStation.heador }}</div>
          <div>装机容量：{{ powerStation.volume > 1000 ? powerStation.volume / 1000 + 'mw' : powerStation.volume + 'kw' }}</div>
          <div>并网时间：{{ powerStation.onGridTime }}</div>
          <div>逆行天数：{{ powerStation.demotime }}天</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    powerStation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.box-center {
  width: 100%;
  height: 260px;

  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  .left {
    width: 68%;
    background-color: #2d5981;
    border-radius: 5px;
  }
  .img {
    width: 20px;
    height: 20px;
  }
  .right {
    width: 31%;
    background-color: #2d5981;
    border-radius: 5px;
  }
  .headers {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #5d7e9c;
    .text {
      margin-left: 10px;
      color: #fff;
      font-weight: 700;
    }
  }
}
.left-bottom {
  text-align: center;
  padding-top: 20px;
  box-sizing: border-box;
  .left-text {
    display: flex;
    justify-content: space-between;
    width: 82%;
    margin: 10px auto;
    color: #fff;
  }
}
.img1 {
  width: 960px;
}
.right-bottom {
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  .right-bottom-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #fff;
    height: 200px;
    font-size: 14px;
  }
  .right-bottom-img {
    width: 150px;
    height: 200px;
    margin-right: 10px;
  }
}

.success {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #00ff00;
  border-radius: 50%;
  margin-right: 5px;
}
.error {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #ff0000;
  border-radius: 50%;
  margin-right: 5px;
}
</style>
