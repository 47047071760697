<template>
  <div class="header">
    <div class="item">
      <div class="yuan">
        <img
          class="img"
          src="../../../../assets/images/Monitor/fadian.png"
          alt=""
        />
      </div>
      <div>
        <div class="number">
          <countTo :startVal="0" :endVal="powerData.currDayPowerNum * 1 ? powerData.currDayPowerNum : 0"  :duration="3000"></countTo>
        </div>
        <div class="text">今日发电量(度)</div>
      </div>
    </div>
    <div class="item">
      <div class="yuan">
        <img
          class="img"
          src="../../../../assets/images/Monitor/fadian.png"
          alt=""
        />
      </div>
      <div>
        <div class="number">
          <countTo :startVal="0" :endVal="powerData.currDayIncome* 1"   :decimals="2" :duration="3000"></countTo>
        </div>
        <div class="text">当日收益（元）</div>
      </div>
    </div>
    <div class="item">
      <div class="yuan">
        <img
          class="img"
          src="../../../../assets/images/Monitor/fadian.png"
          alt=""
        />
      </div>
      <div>
        <div class="number">
          <!-- currDayIncome -->
          <countTo :startVal="0" :endVal="powerData.currDayIncome   ?  powerData.currDayIncome * 1 : 0"   :decimals="2" :duration="3000"></countTo>
        </div>
        <div class="text">当日收益（元）</div>
      </div>
    </div>
    <div class="item">
      <div class="yuan">
        <img
          class="img"
          src="../../../../assets/images/Monitor/fadian.png"
          alt=""
        />
      </div>
      <div>
        <div class="number">
          <countTo :startVal="0" :endVal="powerData.totalPowerNum *1 "   :decimals="2" :duration="3000"></countTo>
        </div>
        <div class="text">累计发电量(度)</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
      powerData:{
        type:Object,
        default:{}
      }
  },

};
</script>

<style lang="scss" scoped>
.header {
  height: 100px;
  background-color: #2d5981;
  border-radius: 10px;
  display: flex;
  align-items: center;
  .item {
    display: flex;
    flex: 1;
    justify-content: center;
    border-right: 1px solid #6685a2;
    &:nth-last-of-type(1) {
      border-right: none;
    }
    .number {
      color: #00f7ee;
      font-weight: 700;
      font-size: 18px;
      padding-bottom: 10px;
    }
    .text {
      color: #fff;
    }
    .yuan {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #50dbb1;
      text-align: center;
      padding-top: 8px;
      box-sizing: border-box;
      margin-right: 15px;
      .img {
        width: 35px;
      }
    }
  }
}
</style>

