<template>
  <div>
    <li class="item">
      <div class="headers">
        <img class="img" src="../../../../assets/images/Monitor/u25.png" />
        <div class="text">实时告警</div>
      </div>
      <div class="item-fourth flex-grow">
        <ul class="fourth-items">
          <li class="item">
            <div class="label">紧急告警（5条）</div>
            <div class="progress">
              <el-progress
                :show-text="false"
                :percentage="5"
                :color="customColorMethod"
              />
            </div>
          </li>
          <li class="item">
            <div class="label">重要告警（21条）</div>
            <div class="progress">
              <el-progress
                :show-text="false"
                :percentage="21"
                :color="customColorMethod"
              />
            </div>
          </li>
          <li class="item">
            <div class="label">次要告警（15条）</div>
            <div class="progress">
              <el-progress
                :show-text="false"
                :percentage="15"
                :color="customColorMethod"
              />
            </div>
          </li>
          <li class="item">
            <div class="label">提示告警（0条）</div>
            <div class="progress">
              <el-progress
                :show-text="false"
                :percentage="50"
                :color="customColorMethod"
              />
            </div>
          </li>
        </ul>
      </div>
    </li>
  </div>
</template>

<script>
export default {
  props:{
   
  },
  methods: {
    customColorMethod(percentage) {
      if (percentage < 6) {
        return "#D9001B";
      }
      if (percentage < 20) {
        return "#dc851f";
      }
      if (percentage < 50) {
        return "#e7bb34";
      }

      return "#facd91";
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  list-style: none;
  margin-bottom: 10px;
}
.headers {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #5d7e9c;
  .text {
    margin-left: 10px;
    color: #fff;
    font-weight: 700;
  }
}
.img {
  width: 20px;
  height: 20px;
}

.item-fourth {
  display: flex;
  align-items: center;
  .fourth-left {
    height: 100%;
    width: 100%;
  }
  ul.fourth-items {
    box-sizing: border-box;
    padding:14px 8px;
    margin: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li.item {
      border: none;
      .label {
        color: #97b3cb;
        padding-bottom: 2px;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
      /deep/.progress {
        color: red;
        .el-progress-bar__outer {
          background-color: #132c45 !important;
          border: 1px solid #02a7f0 !important;
        }
      }
    }
  }
}
</style>
