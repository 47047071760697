<template>
  <div>
    <div class="headers">
      <div style="display: flex; align-item: center">
        <img class="img" src="../../../../assets/images/Monitor/u25.png" />
        <div class="text">统计报表</div>
      </div>
      <div class="flex2">
        <!-- 日期选择 -->
        <div>
          <i class="el-icon-arrow-left" style="color: #fff"></i>
          <span style="color: #fff">
            <el-date-picker
              v-model="date"
              @change="changeDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </span>
          <i class="el-icon-arrow-right" style="color: #fff"></i>
        </div>

        <!-- 年月日切换 -->
        <div class="tabs">
          <div
            class="item"
            :class="activeIndex == 0 ? 'active' : ''"
            @click="changeTab(0)"
          >
            日
          </div>
          <div
            class="item"
            :class="activeIndex == 1 ? 'active' : ''"
            @click="changeTab(1)"
          >
            月
          </div>
          <div
            class="item"
            :class="activeIndex == 2 ? 'active' : ''"
            @click="changeTab(2)"
          >
            年
          </div>
          <div
            class="item"
            :class="activeIndex == 3 ? 'active' : ''"
            @click="changeTab(3)"
          >
            寿命期
          </div>
        </div>
      </div>
    </div>
    <div>
      <div id="DetailLeft2" class="charts"></div>
    </div>
  </div>
</template>

<script>
import {
  DetailLeft2,
  year,
  month,
  day,
} from "../../Menu/echartsOptions/DetailEcharts";
import * as echarts from "echarts";
export default {
  data() {
    return {
      date: new Date(),
      activeIndex: 2,
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.DetailLeft2 = echarts.init(document.getElementById("DetailLeft2"));
        let option = DetailLeft2;
        this.DetailLeft2.setOption(option);
      }, 200);
      window.onresize = function () {
        if (this.DetailLeft2) {
          this.DetailLeft2.resize(); // 调用 ECharts 实例的 resize 方法
        }
      }.bind(this);
    },
    // 选择日期
    changeDate(val) {
      if (val) {
        this.data = val;
        let option = DetailLeft2;
        let randomNumbers = [];
        for (let i = 0; i < 24; i++) {
          let randomNumber = Math.floor(Math.random() * 6);
          randomNumbers.push(randomNumber);
        }
        option.xAxis[0].data = day;
        option.series[0].data = randomNumbers;
        this.DetailLeft2.setOption(option);
      }
    },
    // tab切换
    changeTab(val) {
      this.activeIndex = val;
      let option = DetailLeft2;
      let randomNumbers = [];
      if (val == 0) {
        for (let i = 0; i < 24; i++) {
          let randomNumber = Math.floor(Math.random() * 6);
          randomNumbers.push(randomNumber);
        }
        option.xAxis[0].data = day;
        option.series[0].data = randomNumbers;
      } else if (val == 1) {
        for (let i = 0; i < 31; i++) {
          let randomNumber = Math.floor(Math.random() * 6);
          randomNumbers.push(randomNumber);
        }
        option.series[0].data = randomNumbers;
        option.xAxis[0].data = month;
      } else if (val == 2) {
        for (let i = 0; i < 12; i++) {
          let randomNumber = Math.floor(Math.random() * 6);
          randomNumbers.push(randomNumber);
        }
        option.series[0].data = randomNumbers;
        option.xAxis[0].data = year;
      }
      this.DetailLeft2.setOption(option);
    },
  },
  mounted() {
    this.init();
  },
  beforeDestory() {
    this.DetailLeft2.dispose();
  },
};
</script>

<style lang="scss" scoped>
.headers {
  display: flex;
  align-items: center;
  padding : 10px 20px 20px 10px;
  justify-content: space-between;
  border-bottom: 1px solid #5d7e9c;
  .text {
    margin-left: 10px;
    display: flex;
    color: #fff;
    font-weight: 700;
    width: 220px;
    justify-content: space-between;
  }
}
.img {
  width: 20px;
  height: 20px;
}

.charts {
  width: 100%;
  height: 300px;
}

::v-deep .el-input--small .el-input__inner {
  border: none !important;
  background: none !important;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px !important;
}
::v-deep .el-icon-date:before {
  content: "";
}
::v-deep .el-icon-circle-close:before {
  content: "";
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 19px !important;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 0px !important;
  color: #fff !important;
}

.tabs {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-weight: 700;
  width: 400px;
  border: 1px solid#02a7f0;
  align-items: center;
  margin-left: 50px;
  height: 30px;
  line-height: 30px;
  border-radius: 15px;

  .item {
    width: 100px;
    text-align: center;
  }
  .item:nth-child(1) {
    border-radius: 15px 0 0 15px;
  }
  .item:nth-child(4) {
    border-radius: 0 15px 15px 0;
  }
}

.flex2 {
  display: flex;
  align-items: center;
}

.active {
  background-color: #02a7f0;
}
</style>
