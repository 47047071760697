<template>
  <div>
    <div class="headers">
      <div style="display: flex; align-item: center">
        <img class="img" src="../../../../assets/images/Monitor/u25.png" />
        <div class="text">功率曲线图</div>
      </div>
      <div>
        <div>
          <i class="el-icon-arrow-left" style="color: #fff"></i>
          <span style="color: #fff">
            <el-date-picker
              v-model="date"
              @change="changeDate"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            >
            </el-date-picker>
          </span>
          <i class="el-icon-arrow-right" style="color: #fff"></i>
        </div>
      </div>
    </div>
    <div>
      <div id="DetailLeft1" class="charts"></div>
    </div>
  </div>
</template>

<script>
import { DetailLeft1 } from "../../Menu/echartsOptions/DetailEcharts";
import * as echarts from "echarts";
export default {
  data() {
    return {
      showPicker: false,
      date: new Date(),
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.DetailLeft1 = echarts.init(document.getElementById("DetailLeft1"));
        let option = DetailLeft1;
        this.DetailLeft1.setOption(option);
      }, 200);
      window.onresize = function () {
        if (this.DetailLeft1) {
          this.DetailLeft1.resize(); // 调用 ECharts 实例的 resize 方法
        }
      }.bind(this);
    },
    changeDate(val) {
      if (val) {
        this.date = val;
        let option = DetailLeft1;
        option.series[0].data = [];
        let randomNumbers = [];
        for (let i = 0; i < 24; i++) {
          let randomNumber = Math.floor(Math.random() * 6);
          randomNumbers.push(randomNumber);
        }
        option.series[0].data = randomNumbers;
        // option.series[1].data = randomNumbers;
        this.$nextTick(() => {
          this.DetailLeft1.setOption(option);
        });
      }
    },
  },
  mounted() {
    this.init();
  },
  beforeDestory() {
    this.DetailLeft1.dispose();
  },
};
</script>

<style lang="scss" scoped>
.headers {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #5d7e9c;
  justify-content: space-between;
  .text {
    margin-left: 10px;
    color: #fff;
    font-weight: 700;
  }
  .img {
    width: 20px;
    height: 20px;
  }
}

.charts {
  width: 100%;
  height: 300px;
}

::v-deep .el-input--small .el-input__inner {
  border: none !important;
  background: none !important;
}
::v-deep .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 140px !important;
}
::v-deep .el-icon-date:before {
  content: "";
}
::v-deep .el-icon-circle-close:before {
  content: "";
}
::v-deep .el-input--prefix .el-input__inner {
  padding-left: 19px!important;
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 0px !important;
  color: #fff !important;
}
</style>
